import { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
} from "@mui/material";
import { tokens } from "../../theme";
import { Send, SpeakerNotes } from "@mui/icons-material";
import Header from "../../components/Header";
import axiosInstance from "../../api/axios";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ChatContainer } from "../../styles/Container.Styled";
import { ArrowLeft } from "@mui/icons-material";
import phlokkAvatar from "../../images/loginLogo_2023.png";
import { userName } from "../../services/jwt";

const SUPPORT_NOTIFICATIONS_URL = "api/users/getSupportNotifications/";
const NOTIFY_USER_URL = "api/users/notifyUser";

const NotifyUser = () => {
  const username = userName();
  const navigate = useNavigate();
  const [supportNotifications, setSupportNotifications] = useState([]);
  const { id } = useParams();
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim() !== "") {
      setSupportNotifications(
        (prev) =>
          (prev = [
            ...supportNotifications,
            {
              id: supportNotifications.length,
              response: input,
              notifier: username,
            },
          ])
      );
      notifyUser();
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const notifyUser = async () => {
    await axiosInstance
      .post(NOTIFY_USER_URL, {
        userId: id,
        response: input,
        added_by: username,
      })
      .then((res) => {
        setInput("");
      });
  };

  const getSupportNotifications = async () => {
    await axiosInstance.get(SUPPORT_NOTIFICATIONS_URL + id).then((res) => {
      console.log(res.data);
      setSupportNotifications(res.data);
    });
  };

  useEffect(() => {
    getSupportNotifications();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px" p="25px">
      <Header title="User" subtitle="Video Notice" />
      <ChatContainer>
        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
          <Box sx={{ pb: 2, display: "flex", justifyContent: "flex-start" }}>
            <Button
              startIcon={<ArrowLeft />}
              style={{
                borderColor: "#353b48",
                borderWidth: 1,
                borderStyle: "solid",
                color: "#fff",
              }}
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              back
            </Button>
          </Box>
          {supportNotifications?.map((message) => (
            <Message key={message._id} message={message} />
          ))}
        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  pb: 2,
                  pt: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Link to={"/knowledge-violator"}>
                  <Button
                    startIcon={<SpeakerNotes />}
                    color="secondary"
                    variant="outlined"
                  >
                    Responses
                  </Button>
                </Link>
              </Box>
              <TextField
                maxRows={3}
                minRows={3}
                inputProps={{ maxLength: 1500 }}
                multiline
                fullWidth
                placeholder="Push Notification..."
                value={input}
                onChange={(e) => handleInputChange(e)}
              />
              <Grid sx={{ pt: 3 }} item xs={2}>
                <Button
                  sx={{ pr: 3, pl: 3 }}
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={() => handleSend()}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ChatContainer>
    </Box>
  );
};

const Message = ({ message }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        mb: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          backgroundColor: "#0c101b",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          borderColor: "#00CEC9",
          borderWidth: 2,
          borderStyle: "solid",
          textAlign: "left",
          width: 400,
        }}
      >
        <Avatar width={60} src={phlokkAvatar} />
        <Typography pt={3} variant="body1">
          {message.response}
        </Typography>
        <Typography pt={3} sx={{ color: colors.greenAccent[500] }}>
          {message.notifier ? "Employee ID:" : null}{" "}
        </Typography>
        <Typography variant="body1">
          {message.notifier ? message.notifier : null}
        </Typography>
        <Typography pb={1} variant="body1">
          {moment(message.created_at).format("YYYY-MM-DD HH:MM")}
        </Typography>
      </Paper>
    </Box>
  );
};

export default NotifyUser;
