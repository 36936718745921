import { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosInstance from "../../api/axios";
import moment from 'moment';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


const CAROUSEL_URL = "api/carousel/carouselData";
const Carousel = () => {

  const [data, setData] = useState([]);


   const getCarouselData = async () => {
    await axiosInstance.get(CAROUSEL_URL).then((res) => {
        setData(res.data[0])
       
    });
  };
  
 useEffect(() => {
  getCarouselData();
  }, []);



  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
   
    {
      field: "artist",
      headerName: "Artist",
    },
    
    {
      field: "ad_name",
      headerName: "Title",
      width: 200,
    },
    {
      field: "link",
      headerName: "Buy Link",
      width: 80,
      renderCell: (cellValues) => {
        return <a target="_blank"
        rel="noreferrer" href={`${cellValues.row.link}`}><OpenInNewOutlinedIcon sx={{ color: '#00CEC9'}}/></a>;
      }
    },
    {
      field: "carousel_url",
      headerName: "Url",
      renderCell: (cellValues) => {
        return <a target="_blank"
        rel="noreferrer" href={`${cellValues.row.carousel_url}`}><OpenInNewOutlinedIcon sx={{ color: '#00CEC9'}}/></a>;
      }
    },
    
    {
      field: "height",
      type: "number",
      headerName: "Height",
      width: 80,
    },
    {
      field: "width",
      type: "number",
      headerName: "Width",
      width: 80,
    },
    {
      field: "sort_order",
      type: "number",
      headerName: "Order",
      width: 80,
    },
    {
      field: "created_at",
      headerName: "Created On",
      width: 80,
      renderCell: params=>moment(params.row.created_at).format('YYYY-MM-DD'),
    },
    { field: "_id", headerName: "ID" },
  ];

    const rows = data.map((row) => ({
          artist: row.artist,
          ad_name: row.ad_name,
          link: row.link,
          carousel_url: row.carousel_url,
          height: row.height,
          width: row.width,
          sort_order: row.sort_order,
          created_at: row.created_at,
          _id: row._id,
      }))


  return (
    <Box m="20px">
      <Header title="Carousel" subtitle="Slider Images" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) => 
              theme.palette.mode === 'light' ? colors.primary[100] : colors.primary[700],

            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Carousel;