import { useState, useEffect } from "react";
import { Box, Button, useTheme, Typography, Divider } from "@mui/material";
import { tokens } from "../../theme";
import axiosInstance from "../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { UserContainer } from "../../styles/Container.Styled";
import { Link } from "react-router-dom";
import {
  ChatOutlined,
  ArrowLeft,
  Favorite,
  OnlinePrediction,
  Person,
  FormatQuote,
  List,
  BorderColor,
  EmojiEvents,
  School,
} from "@mui/icons-material";
import LinkIcon from '@mui/icons-material/Link';
import Avatar from "@mui/material/Avatar";
import UserBox from "../../components/UserBox";

const SINGLE_USER_URL = "api/app/getSingleUser/";

const UsersProfile = () => {
  const navigate = useNavigate();
  const [singleUser, setSingleUser] = useState({});
  const { id } = useParams();

  const getSingleUser = async () => {
    await axiosInstance.get(SINGLE_USER_URL + id).then((res) => {
      setSingleUser(res.data[0][0]);
    });
  };

  useEffect(() => {
    getSingleUser();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <UserContainer>
      <Box sx={{ padding: 5 }}>
        <Button
          startIcon={<ArrowLeft />}
          style={{ borderColor: "#353b48", borderWidth: 1, borderStyle: "solid", color: "#fff" }}
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          back
        </Button>
        <Divider role="presentation" sx={{ pb: 2 }}>
          ACCOUNT INFO
        </Divider>
        <Box>
          <Avatar
            src={singleUser.photo_url}
            className="avatar"
            sx={{ width: 120, height: 120 }}
            alt="not found"
          />
        </Box>
        <Divider textAlign="left" sx={{ pb: 2, pt: 2 }}>
          PROFILE
        </Divider>

        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            gridTemplateColumns: "repeat(12, 1fr)",
            gridAutoRows: "minmax(150px, auto)",
            flexDirection: "column",
          }}
          gap="20px"
        >
          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <Typography sx={{ pb: 2 }}>
              <br />
              <Link sx={{ pb: 2 }} to={`/notify-user/${singleUser._id}`}>
                <Button color="secondary" variant="outlined">
                  <ChatOutlined sx={{ color: "#00CEC9", marginRight: 1 }} />
                  Message
                </Button>
              </Link>
            </Typography>
          </Box>
          {/* ROW 1 */}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Status:"
              subtitle={singleUser.status ? singleUser.status : "N/A"}
              icon={
                <OnlinePrediction
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Username:"
              subtitle={singleUser.username ? singleUser.username : "N/A"}
              icon={
                <Person
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Creator:"
              subtitle={
                singleUser.creator_type ? singleUser.creator_type : "N/A"
              }
              icon={
                <List
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Relationship:"
              subtitle={
                singleUser.relationship_type
                  ? singleUser.relationship_type
                  : "N/A"
              }
              icon={
                <Favorite
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* ROW 2 */}

          <Box
            gridColumn="span 6"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Quote:"
              subtitle={singleUser.quote ? singleUser.quote : "N/A"}
              icon={
                <FormatQuote
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 6"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Bio:"
              subtitle={singleUser.bio ? singleUser.bio : "N/A"}
              icon={
                <BorderColor
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 6"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Skills:"
              subtitle={singleUser.skills ? singleUser.skills : "N/A"}
              icon={
                <EmojiEvents
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 6"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Education:"
              subtitle={singleUser.education ? singleUser.education : "N/A"}
              icon={
                <School
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* ROW 3 */}

          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Link:"
              // subtitle={singleUser.link  ? singleUser.link  : ("N/A")}
              link={singleUser.link ? singleUser.link : "N/A"}
              icon={
                <LinkIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Youtube Link:"
              link={singleUser.youtube_link ? singleUser.youtube_link : "N/A"}
              icon={
                <LinkIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <UserBox
              title="Instagram Link:"
              link={
                singleUser.instagram_link ? singleUser.instagram_link : "N/A"
              }
              icon={
                <LinkIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
    </UserContainer>
  );
};

export default UsersProfile;
