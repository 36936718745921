import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const MainLayout = () => {
  const [theme, colorMode] = useMode();
  return (
    localStorage.getItem('auth') ? 
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <div>
              <Outlet></Outlet>
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
    :
    <Navigate to="/" />
  );
};

export default MainLayout;
