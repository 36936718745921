import { TextField } from "@mui/material";
import styled from "styled-components";

export const HeroContainer = styled.div`
  color: #eff3f7;
  max-width: 100%;
  margin-top: 50px;
`;

export const BoxContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const ChatContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: #0c101b;
  padding: 20px;
  
`;

export const ArtistContainer = styled.div`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: #0c101b; 
`;

export const UserContainer = styled.div`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: #0c101b; 
`;

export const KnowledgeContainer = styled.div`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const LoginBox = styled.div`
  max-width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const RegisterBox = styled.div`
  max-width: 550px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  width: 75%;
  height: auto;
  display: flex;
`;

export const SubButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

export const SubscribeContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  background: rgba(223, 228, 234, 1);

  @media (max-width: 800px) {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    width: 100%;
    height: auto;
  }
`;

export const PromotionsContainer = styled.div`
  background-color: rgba(210, 218, 255, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1464;
`;

export const HeaderBox = styled.div`
  max-width: 1240px;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  min-height: 100vh;
  flex-direction: column;


`;

export const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: #eff3f7;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #eff3f7;
    }
    &:hover fieldset {
      border-color: #ced6e0;
    }
    &.Mui-focused fieldset {
      border-color: #eff3f7;
    }
  }
`;
