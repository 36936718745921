import { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosInstance from "../../api/axios";
import moment from 'moment';


const NEWS_URL = "api/news/tickerData";
const NewsTicker = () => {

  const [data, setData] = useState([]);


   const getNewsTickerData = async () => {
    await axiosInstance.get(NEWS_URL).then((res) => {
        setData(res.data[0])
       
    });
  };
  
 useEffect(() => {
  getNewsTickerData();
  }, []);



  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "ticker_name",
      headerName: "Title",
      width: 80,
    },
    {
      field: "ticker_description",
      headerName: "Description",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "ticker_message",
      headerName: "Message",
      width: 200,
    },
    
    {
      field: "created_at",
      type: "number",
      headerName: "Created On",
      renderCell: params=>moment(params.row.created_at).format('YYYY-MM-DD'),
      width: 80,
    },
    {
      field: "updated_at",
      type: "number",
      headerName: "Updated Date",
      renderCell: params=>moment(params.row.updated_at).format('YYYY-MM-DD'),
      width: 80,
    },
    {
      field: "ticker_order",
      type: "number",
      headerName: "Order",
      width: 80,
    },
    { field: "_id", headerName: "ID" },
  ];

    const rows = data.map((row) => ({

          ticker_name: row.ticker_name,
          ticker_description: row.ticker_description,
          ticker_message: row.ticker_message,
          ticker_order: row.ticker_order,
          created_at: row.created_at,
          updated_at: row.updated_at,
          _id: row._id,
          
      }))


  return (
    <Box m="20px">
      <Header title="Updates" subtitle="News Ticker" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) => 
              theme.palette.mode === 'light' ? colors.primary[100] : colors.primary[700],

            },
          }}
        />
      </Box>
    </Box>
  );
};

export default NewsTicker;