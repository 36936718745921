import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import {
  HomeOutlined,
  PeopleOutlined,
  BarChartOutlined,
  PieChartOutlineOutlined,
  TimelineOutlined,
  MapOutlined,
  LibraryMusicOutlined,
  ViewCarouselOutlined,
  NewspaperOutlined,
  GroupsOutlined,
  ForumOutlined,
  AdminPanelSettingsOutlined,
  ViewListOutlined,
  QueueMusicOutlined,
  NoteAddOutlined,
  PlaylistAddOutlined,
  ShowChartOutlined,
  VerifiedOutlined,
  PersonAddOutlined,
  AccountBalanceOutlined,
  ArrowRightOutlined,
  MenuOutlined,
} from "@mui/icons-material";
import { userRole } from "../../services/jwt";
import { userName } from "../../services/jwt";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const username = userName();
  const theme = useTheme();
  const role = userRole();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  useEffect(() => {
    if (window.innerWidth <= 400) {
      setIsCollapsed((prev) => (prev = true));
    }
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Phlokk
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlined />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/phlokk_logo.svg`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {role}
                </Typography>
                <Typography variant="h5" color={colors.grey[100]}>
                {username}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <SubMenu title="Dashboard" icon={<HomeOutlined />}>
              {role === "admin" ? (
                <Item
                  title="Admin Dashboard"
                  to="/admin-dashboard"
                  icon={<ArrowRightOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
              ) : null}
              {role === "admin" || role === "security" ? (
                <Item
                  title="Security Dashboard"
                  to="/security-dashboard"
                  icon={<ArrowRightOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
              ) : null}
              {role === "csr" || role === "admin" ? (
                <Item
                  title="Support Dashboard"
                  to="/support-dashboard"
                  icon={<ArrowRightOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
              ) : null}
            </SubMenu>
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Menu
            </Typography>
            {role === "csr" || role === "admin" ? (
              <SubMenu title="Support" icon={<ForumOutlined />}>
                <Item
                  title="Reports"
                  to="/reports"
                  icon={<ForumOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {role === "security" || role === "admin" ? (
                  <Item
                    title="Security Reports"
                    to="/security-reports"
                    icon={<ForumOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ) : null}
              </SubMenu>
            ) : null}
            {role === "admin" ? (
              <>
                <SubMenu title="Music" icon={<QueueMusicOutlined />}>
                  <Item
                    title="Sounds"
                    to="/sounds"
                    icon={<LibraryMusicOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Add Sounds"
                    to="/addSounds"
                    icon={<PlaylistAddOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>

                <Item
                  title="Carousel"
                  to="/carousel"
                  icon={<ViewCarouselOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <SubMenu title="Ticker" icon={<NewspaperOutlined />}>
                  <Item
                    title="News Ticker"
                    to="/ticker"
                    icon={<NewspaperOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Updates"
                    to="/updates"
                    icon={<NoteAddOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              </>
            ) : null}
            {role === "admin" || role === "csr" || role === "security" ? (
              <SubMenu title="Founders" icon={<PeopleOutlined />}>
                <Item
                  title="Verified"
                  to="/verified"
                  icon={<VerifiedOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {role === "admin" ? (
                  <Item
                    title="Add Founder"
                    to="addFounders"
                    icon={<PersonAddOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ) : null}
              </SubMenu>
            ) : null}
            <Item
              title="Users"
              to="/users"
              icon={<GroupsOutlined />}
              selected={selected}
              setSelected={setSelected}
            />

            {role === "admin" ? (
              <>
                <SubMenu title="Admin" icon={<ViewListOutlined />}>
                  <Item
                    title="Employees"
                    to="/employees"
                    icon={<ViewListOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Register"
                    to="/register"
                    icon={<AdminPanelSettingsOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Financial
                </Typography>
                <SubMenu title="Purchases" icon={<AccountBalanceOutlined />}>
                  <Item
                    title="Gifting"
                    to="/transactions"
                    icon={<ArrowRightOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Market Subscription"
                    to="/transactions"
                    icon={<ArrowRightOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Audio Subscription"
                    to="/transactions"
                    icon={<ArrowRightOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Streaming Subscription"
                    to="/transactions"
                    icon={<ArrowRightOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              </>
            ) : null}
            {role === "admin" ? (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Charts
                </Typography>
                <SubMenu title="Analytics" icon={<ShowChartOutlined />}>
                  <Item
                    title="Bar Chart"
                    to="/bar"
                    icon={<BarChartOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Pie Chart"
                    to="/pie"
                    icon={<PieChartOutlineOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Line Chart"
                    to="/line"
                    icon={<TimelineOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Geography Chart"
                    to="/geography"
                    icon={<MapOutlined />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              </>
            ) : null}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
