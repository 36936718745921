import { useState, useEffect } from "react";
import { Box, useTheme, Typography, Divider, Button } from "@mui/material";
import {
  PlayCircleOutline,
  ArrowLeft,
  Person,
  AudioFile,
  WatchLater,
  List,
  Album,
  CalendarMonth,
  Link,
  LibraryMusic
} from "@mui/icons-material";
import { tokens } from "../../theme";
import axiosInstance from "../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { ArtistContainer } from "../../styles/Container.Styled";
import SoundBox from "../../components/SoundBox";

const SINGLE_ARTIST_URL = "api/sounds/getSingleArtist/";

const ArtistScreen = () => {
  const navigate = useNavigate();
  const [singleSong, setSingleSong] = useState({});
  const { id } = useParams();

  const getSingleArtist = async () => {
    await axiosInstance.get(SINGLE_ARTIST_URL + id).then((res) => {
      setSingleSong(res.data[0][0]);
    });
  };

  useEffect(() => {
    getSingleArtist();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ArtistContainer>
      <Box sx={{ padding: 5 }}>
        <Button
          startIcon={<ArrowLeft />}
          style={{ borderColor: "#353b48", borderWidth: 1, borderStyle: "solid", color: "#fff" }}
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          back
        </Button>
        <Divider role="presentation" sx={{ pb: 2 }}>
          SOUND PROFILE
        </Divider>
        <Box>
          <img
            src={singleSong.artwork_url}
            sx={{ borderRadius: 15 }}
            width={250}
            height={250}
            alt="album cover"
          />
        </Box>
        <Divider textAlign="left" sx={{ pb: 2, pt: 2 }}>
          ARTIST PROFILE
        </Divider>

        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            gridTemplateColumns: "repeat(12, 1fr)",
            gridAutoRows: "minmax(150px, auto)",
            flexDirection: "column",
          }}
          gap="20px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Artist:"
              subtitle={singleSong.artist ? singleSong.artist : "N/A"}
              icon={
                <Person
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Song title:"
              subtitle={singleSong.song_name ? singleSong.song_name : "N/A"}
              icon={
                <LibraryMusic
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Duration:"
              subtitle={singleSong.duration ? singleSong.duration : "N/A"}
              icon={
                <WatchLater
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Genre:"
              subtitle={singleSong.genre ? singleSong.genre : "N/A"}
              icon={
                <List
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* ROW 2 */}

          <Box
            gridColumn="span 6"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Label:"
              subtitle={singleSong.label ? singleSong.label : "N/A"}
              icon={
                <Album
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 6"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
            padding="20px"
          >
            <SoundBox
              title="Release Date:"
              subtitle={
                singleSong.release_date ? singleSong.release_date : "N/A"
              }
              icon={
                <CalendarMonth
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* ROW 3 */}

          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="MP3:"
              link={singleSong.sound_url ? singleSong.sound_url : "N/A"}
              icon={
                <AudioFile
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Artist Buy Link:"
              link={singleSong.link ? singleSong.link : "N/A"}
              icon={
                <Link
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="10px"
            paddingBottom="10px"
          >
            <SoundBox
              title="Author Buy Link:"
              link={singleSong.author_link ? singleSong.author_link : "N/A"}
              icon={
                <Link
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
    </ArtistContainer>
  );
};

export default ArtistScreen;
