import { useState, useEffect } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/MockData";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import EmployeeLineChart from "../../components/EmployeeLineChart";
import axiosInstance from "../../api/axios";
import {HighlightOff, DownloadOutlined, Flag, Announcement, ManageAccounts, Videocam, Support} from '@mui/icons-material';
import { userName } from "../../services/jwt";

const REPORTS_URL = "api/support/videoReportData";
const CLOSED_REPORTS_URL = "api/support/closedReportsData";

// const GET_DATE_COUNT_REPORTS = "api/support/getReportCountByDates";

const EmployeeDashboard = () => {
  const username = userName();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openReportsCount, setOpenReportsCount] = useState([]);
  const [totalReportsCount, setTotalReportsCount] = useState([]);
  const [closedReportsCount, setClosedReportsCount] = useState([]);
  const [videoReportsCount, setVideoReportsCount] = useState([]);
  const [creatorReportsCount, setCreatorReportsCount] = useState([]);
  const [supportReportsCount, setSupportReportsCount] = useState([]);
  // const [weeklyReportsCount, setWeeklyReportsCount] = useState([]);
  // const [dailyReportsCount, setDailyReportsCount] = useState([]);

  //  REPORTS_URL
  useEffect(() => {
    const getReportsCount = async () => {
      try {
          const res = await axiosInstance.get(REPORTS_URL);
          const reportsCount =
          res.data?.filter((item) => item._id !== null)?.length || 0;
          const openCount =
          res.data?.filter((item) => item.status === "open" && item.assign_to !== "security")?.length || 0;
          const videoReportCount =
          res.data?.filter((item) => item.type === "video")?.length || 0;
          const creatorReportCount =
          res.data?.filter((item) => item.type === "creator")?.length || 0;
          const supportReportCount =
          res.data?.filter((item) => item.type === "support")?.length || 0;

        setOpenReportsCount(openCount);
        setTotalReportsCount(reportsCount);
        setVideoReportsCount(videoReportCount)
        setCreatorReportsCount(creatorReportCount)
        setSupportReportsCount(supportReportCount)
      } catch (err) {
        console.log("error fetching report data =>", err);
      }
    };
    getReportsCount();
  }, []);

  useEffect(() => {
    const getAllClosedReportsCount = async () => {
      try {
          const res = await axiosInstance.get(CLOSED_REPORTS_URL);
          const getAllClosedReports = 
          res.data?.filter((item) => item._id !== null)?.length || 0;

          setClosedReportsCount(getAllClosedReports)
      } catch (err) {
        console.log("error fetching all closed reports data =>", err);
      }
    };
    getAllClosedReportsCount();
  }, []);


  // GET_DATE_COUNT_REPORTS
  // useEffect(() => {
  //   const getReportCountByDates = async () => {
  //     try {
  //       const res = await axiosInstance.get(GET_DATE_COUNT_REPORTS);
  //       setDailyReportsCount(res.dailyReportsCount[0]);
  //       setWeeklyReportsCount(res.weeklyReportsCount[0]);
  //     } catch (err) {
  //       console.log("error fetching daily, weekly data =>", err);
  //     }
  //   };
  //   getReportCountByDates();
  // }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Customer Support" subtitle={`Welcome back, ${username}`} />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        sx={{
          display: { xs: "flex", md: "grid" },
          gridTemplateColumns: "repeat(12, 1fr)",
          gridAutoRows: "minmax(150px, auto)",
          flexDirection: "column",
        }}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={totalReportsCount}
            subtitle="Total Reports"
            progress="0.75"
            increase="+14%"
            icon={
              <Flag
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={openReportsCount}
            subtitle="Open Reports"
            progress="0.00"
            increase="0%"
            icon={
              <Announcement
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={closedReportsCount}
            subtitle="Closed Reports"
            progress="0.30"
            increase="+5%"
            icon={
              <HighlightOff
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        

        {/* GRID & CHARTS */}

        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={creatorReportsCount}
            subtitle="Creator Reports"
            progress="0.00"
            increase="0%"
            icon={
              <ManageAccounts
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={videoReportsCount}
            subtitle="Video Reports"
            progress="0.75"
            increase="+14%"
            icon={
              <Videocam
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={supportReportsCount}
            subtitle="Support Reports"
            progress="0.30"
            increase="+5%"
            icon={
              <Support
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        

        {/* ROW 3 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Reports Handled
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {totalReportsCount}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlined
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <EmployeeLineChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Reports
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                sx={{
                  backgroundColor: "#0c101b",
                  color: colors.greenAccent[500],
                  border: 1,
                  borderColor: "#00CEC9",
                }}
                // backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px" 
              >
                {/* ${transaction.cost} */}
                open
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeDashboard;
