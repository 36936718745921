import { ResponsiveStream } from "@nivo/stream";
import { streamData as data } from "../data/StreamData";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
const StreamChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (

      <ResponsiveStream
        data={data}
        theme={{
            // background: "#000",
            text: {
                fontSize: 11,
                fill: colors.grey[100],
                outlineWidth: 0,
                outlineColor: "transparent"
            },
            axis: {
                domain: {
                    line: {
                         stroke: colors.grey[100],
                        strokeWidth: 1
                    }
                },
                legend: {
                    text: {
                        fontSize: 12,
                        fill: colors.grey[100],
                        outlineWidth: 0,
                        outlineColor: "transparent"
                    }
                },
                ticks: {
                    line: {
                        stroke: "#777777",
                        strokeWidth: 1
                    },
                    text: {
                        fontSize: 11,
                        fill: colors.grey[100],
                        outlineWidth: 0,
                        outlineColor: "transparent"
                    }
                }
            },
            grid: {
                line: {
                    stroke: "#dddddd",
                    strokeWidth: 1
                }
            },
            legends: {
                title: {
                    text: {
                        fontSize: 11,
                        fill: "#333333",
                        outlineWidth: 0,
                        outlineColor: "transparent"
                    }
                },
                text: {
                    fontSize: 11,
                    fill: "#333333",
                    outlineWidth: 0,
                    outlineColor: "transparent"
                },
                ticks: {
                    line: {},
                    text: {
                        fontSize: 10,
                        fill: "#333333",
                        outlineWidth: 0,
                        outlineColor: "transparent"
                    }
                }
            },
            annotations: {
                text: {
                    fontSize: 13,
                    fill: "#333333",
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1
                },
                link: {
                    stroke: "#000000",
                    strokeWidth: 1,
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1
                },
                outline: {
                    stroke: "#000000",
                    strokeWidth: 2,
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1
                },
                symbol: {
                    fill: "#000000",
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1
                }
            },
            tooltip: {
                container: {
                    background: "#000",
                    fontSize: 12
                },
                basic: {},
                chip: {},
                table: {},
                tableCell: {},
                tableCellValue: {}
            }
        
        }}
        keys={["Videos", "Lives", "Audio"]}
        margin={{
          top: 50,
          right: 180,
          bottom: 50,
          left: 100,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of Sales",
          legendOffset: 36,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of Sales",
          legendOffset: -40,
        }}
        enableGridX={true}
        enableGridY={false}
        offsetType="silhouette"
        colors={{ scheme: "accent" }}
        fillOpacity={0.85}
        borderColor={{ theme: "background" }}
        defs={[
          {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#2c998f',
              size: 4,
              padding: 2,
              stagger: true
          },
          {
              id: 'squares',
              type: 'patternSquares',
              background: 'inherit',
              color: '#e4c912',
              size: 6,
              padding: 2,
              stagger: true
          }
      ]}
      fill={[
          {
              match: {
                  id: 'Videos'
              },
              id: 'dots'
          },
          {
              match: {
                  id: 'Audio'
              },
              id: 'squares'
          }
      ]}
      dotSize={8}
      dotColor={{ from: 'color' }}
      dotBorderWidth={2}
      dotBorderColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  0.7
              ]
          ]
      }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            translateX: 100,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: "#999999",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#d0d1d5",
                },
              },
            ],
          },
        ]}
      />
  );
};

export default StreamChart;