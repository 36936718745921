
import { useState } from "react";
import { Button, Box} from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KnowledgeContainer } from "../../styles/Container.Styled";
import {FileCopyOutlined, ArrowLeft} from '@mui/icons-material';


const KnowledgeUsersBase = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqData = [
    
    {
      id: 1,
      question: "Video Investigation (Guilty)",
      answer: "After further investigation your content does violate our community guidelines. We have removed the video and you are hereby notified of the community guideline violation.",
      ariaControls: "panel1bh-content",
    },
    
    {
      id: 2,
      question: "Report creator notice (Creator is in violation, but not banned)",
      answer: "Following a thorough investigation, we have concluded that you're in violation of our community guidelines. You are hereby notified of the community guideline violation.",
      ariaControls: "panel2bh-content",
    },
  ];

  const controls = faqData.ariaControls;

  return (
    <KnowledgeContainer  m="20px">
      <Header title="Responses" subtitle="Canned Responses" />
      <Box sx={{ pb: 2}}>
      <Button
          startIcon={<ArrowLeft />}
          style={{ borderColor: "#353b48", borderWidth: 1, borderStyle: "solid", color: "#fff" }}
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          back
        </Button>
      </Box>
      
      {faqData.map((faq, i) => (
        <Accordion
          expanded={expanded === faq.ariaControls}
          onChange={handleChange(faq.ariaControls)}
        >
          <AccordionSummary
            sx={{
              backgroundColor: "#0c101b",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={controls}
            id={faq.id}
            key={i}
          >
            <Typography sx={{ color: '#00CEC9', width: "33%", flexShrink: 0 }}>
              {faq.question}
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              backgroundColor: "#0c101b",
            }}
          >
            <Typography sx={{ pb: 2}}>{faq.answer}</Typography>
            <Button onClick={() => {
            navigator.clipboard.writeText(faq.answer)}} variant="outlined" startIcon={<FileCopyOutlined />} sx={{ color: "#00CEC9", borderColor: '#2f3640', borderStyle: "solid", borderWidth: 2 }}>
            Copy
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
    </KnowledgeContainer>
  );
};

export default KnowledgeUsersBase;
