import { useState } from "react";
import {
  BoxContainer,
  RegisterBox,
  StyledTextField,
} from "../../styles/Container.Styled";
import { Button, Typography, Box, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Alert from "@mui/material/Alert";

// import { tokens } from "../../theme";
import axiosInstance from "../../api/axios";

const ADD_NEWS_URL = "api/news/updates";

const Updates = () => {
  const [ticker_name, setTitle] = useState("");
  const [ticker_description, setDescription] = useState("");
  const [ticker_message, setMessage] = useState("");
  const [ticker_order, setOrder] = useState("");
  const [success, setSuccess] = useState(false);

  const successMessage = () => {
    setSuccess(true);
  };

  function resetTextInput() {
    setTitle("");
    setDescription("");
    setMessage("");
    setOrder("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(ADD_NEWS_URL, {
        ticker_name: ticker_name,
        ticker_description: ticker_description,
        ticker_message: ticker_message,
        ticker_order: ticker_order,
      })
      .then(async function () {
        resetTextInput();
        successMessage();
      })
      .catch(function (e) {
        console.log("Error adding News", e);
      });
  };

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  return (
    <>
      <BoxContainer>
        <section>
          <Box sx={{ p: 2 }}>
            <Typography align="center" paddingTop={3}></Typography>
            <Typography
              lineHeight={1.6}
              variant="h3"
              align="center"
              mb={2}
              pb={5}
            >
              Secure Updates
            </Typography>
            <RegisterBox className="loginBox">
              <form className="loginForm" onSubmit={handleSubmit}>
                {success ? (
                  <Alert
                    sx={{ pt: 2, pb: 2 }}
                    icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                    severity="success"
                  >
                    Data entry successful!
                  </Alert>
                ) : null}

                <StyledTextField
                  helperText="Title of Update"
                  label="Title"
                  value={ticker_name}
                  onChange={(e) => setTitle(e.target.value)}
                  margin="normal"
                  required
                />
                <StyledTextField
                  helperText="Description of Update"
                  label="Description"
                  value={ticker_description}
                  onChange={(e) => setDescription(e.target.value)}
                  margin="normal"
                  required
                />
                <StyledTextField
                  helperText="Message of Update"
                  label="Message"
                  value={ticker_message}
                  onChange={(e) => setMessage(e.target.value)}
                  margin="normal"
                  required
                />
                <StyledTextField
                  helperText="Order of Update"
                  label="Order"
                  value={ticker_order}
                  onChange={(e) => setOrder(e.target.value)}
                  margin="normal"
                  required
                />

                <Button
                  disableRipple
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2, p: 2 }}
                >
                  Update
                </Button>
              </form>
            </RegisterBox>
          </Box>
        </section>
      </BoxContainer>
    </>
  );
};

export default Updates;
