
import { useState } from "react";
import { Button, Box} from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KnowledgeContainer } from "../../styles/Container.Styled";
import {FileCopyOutlined, ArrowLeft} from '@mui/icons-material';


const KnowledgeBase = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqData = [
    
    {
      id: 1,
      question: "Video Reporting",
      answer: "We have received your message and are actively investigating the situation. If the creator is causing any disturbance, we recommend taking appropriate action by blocking them.",
      ariaControls: "panel1bh-content",
    },
    {
      id: 2,
      question: "Video Investigation (Not guilty)",
      answer: "After further investigation, the content does not violate our community guidelines. Please block this user if their content is offensive to you.",
      ariaControls: "panel2bh-content",
    },
    {
      id: 3,
      question: "Video Investigation (Guilty)",
      answer: "After further investigation the content does violate our community guidelines. We have removed the video and notified the user of the violation. Thank you for keeping our community safe.",
      ariaControls: "panel3bh-content",
    },
    {
      id: 4,
      question: "Report creator notice",
      answer: "We have received your message and are actively investigating the situation. If the creator is causing any disturbance, we recommend taking appropriate action by blocking them.",
      ariaControls: "panel4bh-content",
    },
    {
      id: 5,
      question: "Report creator notice (Not guilty)",
      answer: "Following a thorough investigation, we have concluded that the creator is not in violation of our community guidelines. Nevertheless, if the creator's behavior is causing any disruptions, we strongly recommend taking appropriate action, such as blocking them.",
      ariaControls: "panel5bh-content",
    },
    
    {
      id: 6,
      question: "Report creator notice (Creator is in violation, but not banned)",
      answer: "Following a thorough investigation, we have concluded that the creator is in violation of our community guidelines. We have notified the user of the violation",
      ariaControls: "panel6bh-content",
    },
    {
      id: 7,
      question: "Report creator notice (Creator has been banned)",
      answer: "Following a thorough investigation, we have concluded that the creator is in violation of our community guidelines. We have removed the creator from our platform.",
      ariaControls: "panel7bh-content",
    },
    
  ];

  const controls = faqData.ariaControls;

  return (
    <KnowledgeContainer  m="20px">
      <Header title="Responses" subtitle="Canned Responses" />
      <Box sx={{ pb: 2}}>
      <Button
          startIcon={<ArrowLeft />}
          style={{ borderColor: "#353b48", borderWidth: 1, borderStyle: "solid", color: "#fff" }}
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          back
        </Button>
      </Box>
      
      {faqData.map((faq, i) => (
        <Accordion
          expanded={expanded === faq.ariaControls}
          onChange={handleChange(faq.ariaControls)}
        >
          <AccordionSummary
            sx={{
              backgroundColor: "#0c101b",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={controls}
            id={faq.id}
            key={i}
          >
            <Typography sx={{ color: '#00CEC9', width: "33%", flexShrink: 0 }}>
              {faq.question}
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              backgroundColor: "#0c101b",
            }}
          >
            <Typography sx={{ pb: 2}}>{faq.answer}</Typography>
            <Button onClick={() => {
            navigator.clipboard.writeText(faq.answer)}} variant="outlined" startIcon={<FileCopyOutlined />} sx={{ color: "#00CEC9", borderColor: '#2f3640', borderStyle: "solid", borderWidth: 2 }}>
            Copy
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
    </KnowledgeContainer>
  );
};

export default KnowledgeBase;
