import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  BoxContainer,
  LoginBox,
  StyledTextField,
} from "../../styles/Container.Styled";
import { Button, Typography, Box } from "@mui/material";
import phlokkLogo from "../../images/phlokk_logo.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import Alert from "@mui/material/Alert";

const LOGIN_URL = "api/auth/login";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setErrorMessage] = useState(false);

  const errorMessage = () => {
    setErrorMessage(true);
  };

  function resetTextInput() {
    setEmail("");
    setPassword("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    axiosInstance
      .post(LOGIN_URL, {
        email: email,
        password: password,
      })
      .then(async function (response) {
        localStorage.setItem("auth", response.data.token);
        navigate("/admin-dashboard");
        resetTextInput();
      })
      .catch(function () {
        errorMessage();
      });
  };

  return (
    <>
      <BoxContainer>
        <section>
          <Box sx={{ p: 2 }}>
            <Typography align="center" paddingTop={3}>
              <img style={{ width: "125px" }} src={phlokkLogo} alt="logo" />
            </Typography>
            <Typography
              lineHeight={1.6}
              variant="h3"
              align="center"
              mb={2}
              pt={5}
              pb={5}
            >
              Secure Login
            </Typography>

            <LoginBox className="loginBox">
              <form className="loginForm" onSubmit={handleSubmit}>
                {error ? (
                  <Alert
                  sx={{pb: 2, pt: 2,}}
                    severity="error"
                    icon={<ErrorOutlineIcon fontSize="inherit" />}
                  >
                    Your username or password is incorrect!
                  </Alert>
                ) : null}
                <StyledTextField
                  helperText="Please enter your email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  required
                  type="email"
                />
                <StyledTextField
                  helperText="Please enter your password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                  required
                  type="password"
                />
                <Button
                  disableRipple
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2, p: 2 }}
                >
                  Login
                </Button>
              </form>
            </LoginBox>
          </Box>
        </section>
      </BoxContainer>
    </>
  );
};

export default Login;
