import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { BoxContainer, HeroContainer } from "../styles/Container.Styled";
import shipwrecked from "../lottie/shipwrecked.json";
import Lottie from "lottie-react";

function NotFound() {
  return (
    <>
      <HeroContainer>
        <BoxContainer>
        <Typography className="lottieDiv" pb={5} pt={16} align="center">
            <Lottie className="lottieShip" animationData={shipwrecked} loop={true} />
            </Typography>
        <Typography variant="h1" fontFamily={"Roboto"}>4 0 4</Typography>
          <Typography fontFamily={"Roboto"} pt={5} pb={5} variant="h4">Page does not exist!</Typography>
          <Typography pb={15} fontFamily={"Roboto"} variant="h5">Go back to<Link to="/"> <span className="homepageLink">Homepage</span></Link></Typography>
        </BoxContainer>
      </HeroContainer>
    </>
  );
}

export default NotFound;
