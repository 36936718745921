import { useState, useEffect, useCallback, forwardRef } from "react";
import { Box, useTheme, Snackbar } from "@mui/material";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, gridClasses } from "@mui/x-data-grid";
import MuiAlert from '@mui/material/Alert';
import { tokens } from "../../theme";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import Header from "../../components/Header";
import axiosInstance from "../../api/axios";
import Avatar from "@mui/material/Avatar";
import moment from 'moment';
import { userRole } from "../../services/jwt";
import { Link } from "react-router-dom";


const TICKET_URL = "api/support/videoReportData";
const CLOSE_TICKET_URL = "api/support/closeTicket";
const ASSIGN_TICKET_URL = "api/support/assignTicket";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Reports = () => {
   const [open, setOpen] = useState(false);
   const role = userRole();
   const [data, setData] = useState([]);

   const getAllVideoTickets = async () => {
    await axiosInstance.get(TICKET_URL).then((res) => {
        setData(res.data)
       
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 useEffect(() => {
  getAllVideoTickets();
  }, []);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    
    {
      field: "photo_url",
      headerName: "Avatar",
      width: 60,
      renderCell: (params) => (
        <Avatar
          src={params.row.photo_url}
        />
      ),
      filterable: false,
      sortable: false,
    },
    
    {
      field: "status",
      headerName: "Status",
      width: 80,
      type: 'singleSelect',
      valueOptions: ['open', 'closed'],
      editable: true,
      cellClassName: "name-column--cell",
    },
    
    {
      field: "creator",
      headerName: "Report from",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "type",
      headerName: "Type of report",
      width: 200,
    },
    {
      field: "violator_username",
      headerName: "Report against",
      width: 200,
      renderCell: ({ row }) => {
        if (!row.violator_username) {
          return "N/A";
        }
        return row.violator_username;
      }
    },
    {
      field: "violator_photo_url",
      headerName: "Violator",
      width: 200,
      renderCell: (params) => (
        params.row.violator_photo_url ? <Avatar
          src={params.row.violator_photo_url}
        /> : ("N/A")
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      cellClassName: "violation-column--cell",
    },
    {
      field: "message",
      headerName: "Message",
      width: 80,
      cellClassName: "name-column--cell",
      renderCell: (cellValues) => {
        return <Link to={`/respond-report/${cellValues.row._id}`}><ChatOutlinedIcon sx={{ color: '#00CEC9'}}/></Link>;
      }
    },
    {
      field: "assign_to",
      headerName: "Assigned To",
      width: 80,
      type: 'singleSelect',
      valueOptions: ['security'],
      editable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 80,
      renderCell: ({ row }) => {
        if (!row.notes) {
          return "No notes";
        }
        return row.notes;
      }
    },
    {
      field: "notes_edited_by",
      headerName: "Edited by",
      renderCell: ({ row }) => {
        if (!row.notes_edited_by) {
          return "N/A";
        }
        return row.notes_edited_by;
      }
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      renderCell: params=>moment(params.row.created_at).format('YYYY-MM-DD HH:MM')
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 200,
      renderCell: params=>moment(params.row.updated_at).format('YYYY-MM-DD HH:MM')
    },
    
    {
      field: "post_id",
      headerName: "Post ID",
      width: 300,
      renderCell: ({ row }) => {
        if (!row.post_id) {
          return "N/A";
        }
        return row.post_id;
      }
    },
    {
      field: "user_id",
      headerName: "User ID",
      width: 300,
    },
    { 
    field: "_id", 
    headerName: "ID", 
    width: 300,
  },
  ];

  const handleUpdated = useCallback(
    async (newRow) => {
      if(newRow.assign_to === "security") {
        await axiosInstance
          .get(ASSIGN_TICKET_URL+'/'+newRow._id)
          .then(async function (response) {
            setOpen(true);
          })
          .catch(function (e) {
            console.log("Error deleting data", e);
          });
          const updateRow = {...newRow};
          setData((prevData) => {
            const updatedData = prevData.filter((item) => {
              if (item._id !== newRow._id) {
                return { ...item };
              }
            });
          
            return updatedData;
          });
          return updateRow;
      }

      if(newRow.status === "closed") {
        await axiosInstance
          .get(CLOSE_TICKET_URL+'/'+newRow._id)
          .then(async function (response) {
            setOpen(true);
          })
          .catch(function (e) {
            console.log("Error deleting data", e);
          });
          const updateRow = {...newRow};
          setData((prevData) => {
            const updatedData = prevData.filter((item) => {
              if (item._id !== newRow._id) {
                return { ...item };
              }
            });
          
            return updatedData;
          });
          return updateRow;
      }
    },
    [],
  );


    const rows = data.map((row) => ({
          photo_url: row.photo_url,
          status: row.status,
          creator: row.creator,
          type: row.type,
          violator_username: row.violator_username,
          violator_photo_url: row.violator_photo_url,
          category: row.category,
          message: row.message,
          assign_to: row.assign_to,
          notes: row.notes,
          notes_edited_by: row.notes_edited_by,
          created_at: row.created_at,
          updated_at: row.updated_at,
          post_id: row.post_id,
          user_id: row.user_id,
          _id: row._id,
      }))

      function CustomGridToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector/>
            {role === "admin"? (
              <GridToolbarExport />
            ):null}
            
          </GridToolbarContainer>
        );
      }
    


  return (
    <Box m="20px">
      <Header title="Support Center" subtitle="Application Reports" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .violation-column--cell": {
              color: '#ffeaa7',
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: CustomGridToolbar}}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          onProcessRowUpdateError={()=>{}}
            processRowUpdate={handleUpdated}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? colors.primary[100]
                  : colors.primary[700],
            },
          }}
        />
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Ticket has been successfully closed
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Reports;
