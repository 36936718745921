import { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridClasses,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import axiosInstance from "../../api/axios";
import { userRole } from "../../services/jwt";

const FOUNDERS_URL = "api/founders/foundersData";
const Founders = () => {
  const role = userRole();
  const [data, setData] = useState([]);

  console.log("role =>", role)

  // FOUNDERS_URL
  useEffect(() => {
    const getAllFounders = async () => {
      try {
        const res = await axiosInstance.get(FOUNDERS_URL);
        setData(res.data);
      } catch (err) {
        console.log("error fetching all data =>", err);
      }
    };
    getAllFounders();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns =
    role === "csr" || role === "manager"
      ? [
          {
            field: "username",
            headerName: "Username",
            cellClassName: "name-column--cell",
            width: 200,
          },
          {
            field: "country",
            headerName: "Country",
            renderCell: ({ row }) => {
              if (!row.country) {
                return "N/A";
              }
              return row.country;
            }
          },
          {
            field: "verification",
            headerName: "ID Verified",
            type: "boolean",
            editable: true,
            width: 80,
          },
          {
            field: "is_founder",
            type: "boolean",
            editable: true,
            headerName: "Founder",
            width: 80,
          },
          {
            field: "is_blocked",
            headerName: "Banned",
            type: "boolean",
            editable: true,
            width: 80,
          },
          {
            field: "created_at",
            headerName: "Sign Up Date",

            renderCell: (params) =>
              moment(params.row.created_at).format("YYYY-MM-DD"),
          },
          {
            field: "updated_at",
            headerName: "Updated at",

            renderCell: (params) =>
              moment(params.row.updated_at).format("YYYY-MM-DD"),
          },
          { field: "id", headerName: "ID" },
        ]
      : [
          {
            field: "name",
            headerName: "Name",
            width: 200,
          },
          {
            field: "username",
            headerName: "Username",
            cellClassName: "name-column--cell",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.username) {
                return "N/A";
              }
              return row.username;
            }
          },
          {
            field: "city",
            headerName: "City",
            renderCell: ({ row }) => {
              if (!row.city) {
                return "N/A";
              }
              return row.city;
            }
          },
          {
            field: "state",
            headerName: "State",
            renderCell: ({ row }) => {
              if (!row.state) {
                return "N/A";
              }
              return row.state;
            }
          },
          {
            field: "country",
            headerName: "Country",
            renderCell: ({ row }) => {
              if (!row.country) {
                return "N/A";
              }
              return row.country;
            }
          },
          {
            field: "email",
            headerName: "Email",
            width: 200,
            cellClassName: "name-column--cell",
          },
          

          {
            field: "verification",
            headerName: "Verified",
            type: "boolean",
            editable: true,
            width: 80,
          },
          {
            field: "is_founder",
            type: "boolean",
            editable: true,
            headerName: "Founder",
            width: 80,
          },
          {
            field: "is_blocked",
            headerName: "Banned",
            type: "boolean",
            editable: true,
            width: 80,
          },
          {
            field: "created_at",
            headerName: "Sign Up Date",

            renderCell: (params) =>
              moment(params.row.created_at).format("YYYY-MM-DD"),
          },
          {
            field: "updated_at",
            headerName: "Updated at",

            renderCell: (params) =>
              moment(params.row.updated_at).format("YYYY-MM-DD"),
          },
          { field: "id", headerName: "ID" },
        ];

  const rows = data.map((row) => ({
    name: row.name,
    username: row.username,
    city: row.city,
    state: row.state,
    country: row.country,
    email: row.email,
    verification: row.verification,
    is_founder: row.is_founder,
    is_blocked: row.is_blocked,
    created_at: row.created_at,
    updated_at: row.updated_at,
    id: row.id,
  }));

  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {role === "admin" ? <GridToolbarExport /> : null}
      </GridToolbarContainer>
    );
  }

  return (
    <Box m="20px">
      <Header title="Founders" subtitle="Verified Creators" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row.id}
          columns={columns}
          slots={{ toolbar: CustomGridToolbar }}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? colors.primary[100]
                  : colors.primary[700],
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Founders;
