
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { ResponsiveCalendar } from "@nivo/calendar";
// import { calendarData as data } from "../data/CalenderData";
import axiosInstance from "../api/axios";

const REPORTS_BY_DATE_URL = "api/support/getReportsByDates";
const CalenderChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reportsByDate, setReportsByDate] = useState([]);

    // FOUNDERS_URL
    useEffect(() => {
      const getAllReportsByDate = async () => {
        try {
          const res = await axiosInstance.get(REPORTS_BY_DATE_URL);
          const reportsByDateList =
            res.data?.filter((item) => item._id !== null);
            setReportsByDate(reportsByDateList);
        } catch (err) {
          console.log("error fetching reports by date =>", err);
        }
      };
      getAllReportsByDate();
    }, []);
  return (


      <ResponsiveCalendar
        data={reportsByDate}
        theme={{
            // background: "#000",
            textColor: theme.palette.mode === 'light' ? colors.primary[100] : "#fff",
            tooltip: {
                container: {
                    background: theme.palette.mode === 'light' ? colors.primary[100] : "#000",
                    fontSize: 12,
                    textColor: theme.palette.mode === 'light' ? colors.primary[100] : "#000",
                },
                basic: {},
                chip: {},
                table: {},
                tableCell: {},
                tableCellValue: {}
            }
        
        }}
        from="2023-10-01"
        to="2024-12-31"
        emptyColor="#1F2A40"
        colors={[
          "#00CEC9",
          "#97e3d5",
          "#70a1ff",
          "#9c88ff",
        ]}
        margin={{
          top: 40,
          right: 40,
          bottom: 40,
          left: 40,
        }}
        yearSpacing={40}
        monthBorderColor="#141b2d"
        dayBorderWidth={2}
        dayBorderColor="#141b2d"
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
  );
};

export default CalenderChart;