import { tokens } from "../theme";


export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2023-09-01",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2023-09-01",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2023-09-01",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2023-09-05",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2023-09-02",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2023-09-01",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2023-09-15",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2023-09-01",
  },
];

// export const mockBarData = [
//   {
//     country: "US",
//     "Icons": 1509,
//     "iconColor": "hsl(229, 70%, 50%)",
//     Merch: 96,
//     merchColor: "hsl(296, 70%, 50%)",
//     Beta: 72,
//     betaColor: "hsl(97, 70%, 50%)",
//   },
//   {
//     country: "AU",
//     "Icons": 250,
//     "iconColor": "hsl(307, 70%, 50%)",
//     Merch: 28,
//     merchColor: "hsl(111, 70%, 50%)",
//     Beta: 58,
//     betaColor: "hsl(273, 70%, 50%)",
//   },
//   {
//     country: "UK",
//     "Icons": 200,
//     "iconColor": "hsl(72, 70%, 50%)",
//     Merch: 23,
//     merchColor: "hsl(96, 70%, 50%)",
//     Beta: 34,
//     betaColor: "hsl(106, 70%, 50%)",
//   },
//   {
//     country: "CA",
//     "Icons": 133,
//     "iconColor": "hsl(257, 70%, 50%)",
//     Merch: 52,
//     merchColor: "hsl(326, 70%, 50%)",
//     Beta: 43,
//     betaColor: "hsl(110, 70%, 50%)",
//   },
//   {
//     country: "NZ",
//     "Icons": 81,
//     "iconColor": "hsl(190, 70%, 50%)",
//     Merch: 80,
//     merchColor: "hsl(325, 70%, 50%)",
//     Beta: 112,
//     betaColor: "hsl(54, 70%, 50%)",
//   },

// ];


export const mockLineData = [
  {
    id: "Glen L",
    color: tokens("dark").blueAccent[100],
    data: [
      {
        x: "Reports",
        y: 0,
      },
      {
        x: "Closed Reports",
        y: 0,
      },
      {
        x: "Security",
        y: 0,
      },
      
    ],
  },
  {
    id: "Bridget F",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "Reports",
        y: 0,
      },
      {
        x: "Closed Reports",
        y: 0,
      },
      {
        x: "Security",
        y: 0,
      },
    ],
  },
  {
    id: "Christina W",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "Reports",
        y: 0,
      },
      {
        x: "Closed Reports",
        y: 0,
      },
      {
        x: "Security",
        y: 0,
      },
    ],
  },
  
  
];