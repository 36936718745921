import { useState } from "react";
import { Typography } from "@mui/material";
import { MdCloudUpload, MdDelete } from "react-icons/md";


const ImageUploader = ({setAudioImage}) => {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected file");

  return (
    <main>
      <form className='image-upload-form' onClick={() => document.querySelector(".input-field").click()}>
        <input
          type="file"
          accept="image/*"
          className="input-field"
          hidden
          onChange={({ target: { files } }) => {
            files[0] && setFileName(files[0].name);
            if (files) {
              setImage(URL.createObjectURL(files[0]));
              setAudioImage(files[0])
            }
          }}
        />

        {image ? (
          <img src={image} width={150} height={150} alt={fileName} />
          
        ) : (
          <>
            <Typography color="#FFFFFF">Browse Image Files To Upload</Typography>
            <MdCloudUpload color="#FFFFFF" size={60} />
          </>
        )}
      </form>
      <section className="uploaded-row">
        <span className="upload-content">
          {fileName}
          <MdDelete
            onClick={() => {
              setFileName("No selected File");
              setImage(null);
            }}
          />
        </span>
      </section>
    </main>
  );
};

export default ImageUploader;