export const streamData = [
  {
    Videos: 416,
    Lives: 17,
    Audio: 37,
  },
  {
    Videos: 500,
    Lives: 11,
    Audio: 54,
  },
  {
    Videos: 156,
    Lives: 266,
    Audio: 441,
  },
  {
    Videos: 196,
    Lives: 160,
    Audio: 453,
  },
  {
    Videos: 480,
    Lives: 167,
    Audio: 294,
  },
  {
    Videos: 103,
    Lives: 194,
    Audio: 188,
  },
  {
    Videos: 114,
    Lives: 105,
    Audio: 40,
  },
  {
    Videos: 55,
    Lives: 12,
    Audio: 49,
  },
  {
    Videos: 78,
    Lives: 29,
    Audio: 90,
  },
];