import { useState } from "react";
import { BoxContainer, RegisterBox, StyledTextField } from "../../styles/Container.Styled";
import { Button, Typography, Box, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Alert from "@mui/material/Alert";

// import { tokens } from "../../theme";
import axiosInstance from "../../api/axios";

const ADD_FOUNDERS_URL = "api/founders/registerFounder";

const AddFounders = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [verification, setVerification] = useState("");
  const [is_founder, setIs_Founder] = useState("");
  const [is_blocked, setIs_Blocked] = useState("");
  const [success, setSuccess] = useState(false);

  const successMessage = () => {
    setSuccess(true);
  }

  function resetTextInput() {
    setName("");
    setUsername("");
    setState("");
    setCountry("");
    setEmail("");
    setCity("");
    setVerification("");
    setIs_Founder("");
    setIs_Blocked("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(ADD_FOUNDERS_URL, {
        name: name,
        username: username,
        state: state,
        country: country,
        email: email,
        city: city,
        verification: verification,
        is_founder: is_founder,
        is_blocked: is_blocked,

      })
      .then(async function (response) {
        const registerFounder = response.data.user[0];
        registerFounder.token = response.data.token;

        resetTextInput();
        successMessage();
      })
      .catch(function (e) {
        console.log("Error registering", e);
      });
  };

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  
  return (
    <>
      <BoxContainer>
        <section>
          <Box sx={{ p: 2 }}>
            <Typography align="center" paddingTop={3}>
            </Typography>
            <Typography
              lineHeight={1.6}
              variant="h3"
              align="center"
              mb={2}
              pb={5}
            >
              Secure Founder Registration
            </Typography>
            <RegisterBox className="loginBox">
            <form className="loginForm" onSubmit={handleSubmit}>
            
          { success ? <Alert sx={{pt: 2, pb: 2,}} icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
        Data entry successful! 
      </Alert> : null}

            <StyledTextField
                helperText="Name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Username"
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="State"
                label="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Country"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                margin="normal"
                required

              />
              <StyledTextField
                helperText="Email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required

              />
              <StyledTextField
                helperText="City"
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                margin="normal"
                required

              />
              <StyledTextField
                helperText="Identified"
                label="ID"
                value={verification}
                onChange={(e) => setVerification(e.target.value)}
                margin="normal"
                required

              />
              <StyledTextField
                helperText="Is Verified"
                label="Founder"
                value={is_founder}
                onChange={(e) => setIs_Founder(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Is Blocked"
                label="Is Blocked"
                value={is_blocked}
                onChange={(e) => setIs_Blocked(e.target.value)}
                margin="normal"
                required
              />
              <Button
              disableRipple
              variant="contained"
              type="submit"
              sx={{ mt: 2, p: 2 }}
            >
              Register
            </Button>
            </form>
            </RegisterBox>
            
          </Box>
        </section>
      </BoxContainer>
    </>
  )
}

export default AddFounders;