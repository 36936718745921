import { useState, useEffect, forwardRef, useCallback } from "react";
import { Box, useTheme, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from 'moment';
import axiosInstance from "../../api/axios";

const EMPLOYEE_URL = "api/employees/employeeData";
const UPDATE_EMPLOYEE_URL = "api/employees/updateEmployeeData";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Employees = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const getEmployees = async () => {
    await axiosInstance.get(EMPLOYEE_URL).then((res) => {
      setData(res.data[0]);
    });
  };
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const columns = [
    
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "username",
      headerName: "Username",
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      type: 'singleSelect',
      valueOptions: ['csr', 'manager', 'security', 'admin'],
      editable: true
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,

      cellClassName: "name-column--cell",
    },
    {
      field: "state",
      headerName: "State",

    },
    { 
    field: "created_at", 
    headerName: "Hired Date", 

    renderCell: params=>moment(params.row.created_at).format('YYYY-MM-DD')
  },
  {
    field: "id",
    headerName: "ID",
  },
  ];

  const handleUpdated = useCallback(
    async (newRow) => {
      console.log('update => ', newRow)
      
      await axiosInstance
        .post(UPDATE_EMPLOYEE_URL, {
          id: newRow.id,
          role: newRow.role
        })
        .then(async function (response) {
          setOpen(true);
        })
        .catch(function (e) {
          console.log("Error registering", e);
        });
        const updateRow = {...newRow};
        setData((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item.id === newRow.id) {
              return { ...item, role: newRow.role };
            }
            return item;
          });
        
          return updatedData;
        });
        return updateRow;
    },
    [],
  );

  const rows = data.map((row) => ({
    name: row.name,
    username: row.username,
    role: row.role,
    email: row.email,
    state: row.state,
    created_at: row.created_at,
    id: row.id,
  }));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box m="20px">
        <Header title="Employees" subtitle="2023-2024" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            checkboxSelection
            rows={rows}
            getRowId={(row) => row.id}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5,
            })}
            onProcessRowUpdateError={()=>{}}
            processRowUpdate={handleUpdated}
            sx={{
              [`& .${gridClasses.row}`]: {
                bgcolor: (theme) => 
                theme.palette.mode === 'light' ? colors.primary[100] : colors.primary[700],
  
              },
            }}
            
          />
        </Box>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Employee updated successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default Employees;
