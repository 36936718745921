import { useState } from "react";
import { Typography } from "@mui/material";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { BsFileEarmarkMusic } from "react-icons/bs";


const AudioUploader = ({setAudio}) => {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected file");

  return (
    <main>
      <form className='audioUploadForm' onClick={() => document.querySelector(".audio-input-field").click()}>
        <input
          type="file"
          accept="audio/*"
          className="audio-input-field"
          name="audioFile"
          hidden
          onChange={({ target: { files } }) => {
            files[0] && setFileName(files[0].name);
            if (files) {
              setImage(URL.createObjectURL(files[0]));
              setAudio(files[0])
            }
          }}
        />

        {image ? (
          // <img src={image} width={150} height={60} alt={filename} />
          <BsFileEarmarkMusic color="#444555" size={60} />
        ) : (
          <>
            <Typography color="#FFFFFF">Browse Audio Files To Upload</Typography>
            <MdCloudUpload color="#FFFFFF" size={60} />
          </>
        )}
      </form>
      <section className="uploaded-row">
        <span className="upload-content">
          {fileName}
          <MdDelete
            onClick={() => {
              setFileName("No selected File");
              setImage(null);
            }}
          />
        </span>
      </section>
    </main>
  );
};

export default AudioUploader;
